import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { AccountService } from '@app/_services';
import { environment } from '@env/environment';
import { Observable, Subscription } from 'rxjs';
import { User } from '@app/_models';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    user$: Observable<User>;
    apiUrl = environment.apiUrl;
    isProd = environment.production;
    @ViewChild('chatModal') chatModal: TemplateRef<any>;

    public dialogRef: MatDialogRef<TemplateRef<any>>;

    constructor(private accountService: AccountService, public dialog: MatDialog) { }

    ngOnInit(): void {
      this.user$ = this.accountService.user;
    }

    isLoggedIn(): boolean {
      return this.accountService.isLoggedIn();
    }

    isOrgAdmin(u: User): boolean {
      return u.organizations.filter(o => o.role === 'admin' ).length > 0;
    }

    canAdmin(u: User): boolean {
      return (u.site_role === 'admin' || this.isOrgAdmin(u));
    }

    logout(): void {
        this.accountService.logout();
    }


  openChatDialog(): void {
    if (this.dialogRef === undefined){
      this.dialogRef = this.dialog.open(this.chatModal,
        {position: { right: '10px'}, width: '600px', height: '600px',
          hasBackdrop: false, panelClass: 'custom-chat-dialog'}
      );
    } else {
      this.dialogRef.addPanelClass('custom-chat-dialog');
      this.dialogRef.removePanelClass('minimize-chat-dialog');
    }
  }

  openChatDialog2(): void {
    const url = 'https://ml.doe-sram.com';
    const windowName = 'ChatWindow';
    const windowFeatures = 'location=1,status=1,width=500,height=500,top=100,left=100';

    window.open(url, windowName, windowFeatures);
  }

  minimizeChatDialog(): void {
    this.dialogRef.removePanelClass('custom-chat-dialog');
    this.dialogRef.addPanelClass('minimize-chat-dialog');
  }

  closeChatDialog(): void {
    this.dialogRef.close();
    this.dialogRef = undefined;
  }


}
