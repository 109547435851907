<mat-toolbar color="primary" class="navbar" *ngIf="isLoggedIn() else notLoggedIn">
    <span class="navbar-left">
        <a class="navbar-brand" [routerLink]="['/answers']">SRAM</a>
        <span class="sw-version"><app-version></app-version></span>
<!--        <button mat-flat-button-->
<!--                      aria-hidden="false"-->
<!--                      (click)="openChatDialog()"-->
<!--                      class="chat-button" >-->
<!--          <mat-icon color="primary">chat</mat-icon>-->
<!--        </button>-->
    </span>
    <div class="" *ngIf="(user$ | async) as user">
        <button mat-button [routerLink]="['/admin/users']" *ngIf="canAdmin(user)">
            Admin
        </button>
        <button mat-button [matMenuTriggerFor]="accountMenu">
            Account <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #accountMenu="matMenu">
            <button mat-menu-item [routerLink]="['/account']">
                <mat-icon>settings</mat-icon> Details & Settings
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon> Logout
            </button>
        </mat-menu>
    </div>
</mat-toolbar>

<ng-template #notLoggedIn>
  <mat-toolbar color="primary" class="navbar">
    <span class="navbar-left">
        <a class="navbar-brand" [routerLink]="['/']">SRAM</a>
        <span class="sw-version"><app-version></app-version></span>
    </span>
  </mat-toolbar>
</ng-template>

<ng-template #chatModal>
  <div style="white-space: nowrap;">
    <div matDialogTitle style="margin-top:-20px; display: inline-block;">
      Chat Help
    </div>
    <button mat-icon-button (click)="closeChatDialog()" aria-hidden="false" aria-label="Close" tabindex="-1" title="Close Chat" style="float: right; display: inline-block; margin-top: -15px; margin-right: -15px"><mat-icon>close</mat-icon></button>
    <button mat-icon-button (click)="minimizeChatDialog()" aria-hidden="false" aria-label="Minimize" tabindex="-1" title="Minimize Chat" style="float: right; display: inline-block; margin-top: -22px; margin-right: -15px"><mat-icon>minimize</mat-icon></button>
  </div>
  <app-chat></app-chat>
</ng-template>
