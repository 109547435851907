<div class="content splash"> 

  
  <div class="splash-message">
    <alert></alert>
    
    <div *ngIf="!declined">
      <p>**WARNING**WARNING** WARNING**WARNING**</p>

      <p>This is a Department of Energy (DOE) computer system. DOE computer systems are provided for the processing of official U.S. government information only. All data contained within DOE computer systems is owned by the DOE, and may be audited, intercepted, recorded, read, copied, or captured in any manner and disclosed in any manner, by authorized personnel. THERE IS NO RIGHT OF PRIVACY IN THIS SYSTEM. System personnel may disclose any potential evidence of crime found on DOE computer systems to appropriate authorities. USE OF THIS SYSTEM BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES CONSENT TO THIS AUDITING, INTERCEPTION, RECORDING, READING, COPYING, CAPTURING, and DISCLOSURE OF COMPUTER ACTIVITY.</p>

      <p>**WARNING**WARNING** WARNING**WARNING**.</p>

      <p>Learn about the Department of Energy's <a href="https://doe.responsibledisclosure.com/hc/en-us" target="_new">Vulnerability Disclosure Program</a></p>

      <div class="splash-decision">
        <button mat-flat-button color="default" (click)="decline()">Decline</button>
        <button mat-flat-button color="primary" (click)="accept()">Accept</button>
      </div>
    </div>

    <div *ngIf="declined">
      Session ended, please close your browser.
    </div>

  </div>

</div>


